import React, { Component } from 'react'
import { navigate } from 'gatsby'
import Layout from '../components/layout'

export default class TariffsPage extends Component {
  componentDidMount () {
    navigate('/info/fotoreportage', { replace: true })
  }

  render () {
    return <Layout />
  }
}
